@import url(https://fonts.googleapis.com/css?family=Quicksand:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
body {
  color: #4a4a4a;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1,h2{
  font-family: "Quicksand", sans-serif;
}

p{
  line-height: 2;
  font-size: 14px;
  font-weight: normal;
}
@media(max-width: 767px){
  button{
    font-size: 12px !important;
  }
}
.lds-background {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
}
.lds-box {
    width: 175px;
    height: 175px;
}
.lds-box .lds-border {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 150px;
    height: 150px;
    margin: 8px;
    border: 4px solid #21406e;
    border-radius: 50%;
    -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #21406e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    -webkit-animation-delay: -0.45s;
            animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    -webkit-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    -webkit-animation-delay: -0.15s;
            animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

