.lds-background {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
}
.lds-box {
    width: 175px;
    height: 175px;
}
.lds-box .lds-border {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 150px;
    height: 150px;
    margin: 8px;
    border: 4px solid #21406e;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #21406e transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
